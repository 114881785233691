<template>
  <div class="pl-4 pr-4 pb-4 pt-4">
    <h2>GO FIND BUILD TERMS AND CONDITIONS</h2>

    <span>Effective Date of Last Revision: November 12, 2022</span>

    <p>
      This site is protected by United States, individual state and
      international copyright, trademark and trade secret laws. All rights
      reserved. In addition to all applicable laws, by accessing and using this
      site, you agree to be subject to the following Terms and Conditions.
      Certain features of the website may only be accessible by registered users
      of the website.
    </p>
    <h4>Copyrights and Trademarks</h4>
    <p>
      All website design, text, graphics, logos, button icons, audio and video
      clips, the selection and arrangement thereof, pricing and financial
      information, and all software is the exclusive property of Go Find Build,
      LLC (“GFB”) or its content suppliers, Copyright © 2022, GFB ALL RIGHTS
      RESERVED.
    </p>
    <p>
      Permission is granted to electronically copy and to print in hard copy
      portions of this website for personal, non-commercial purposes including
      reviewing information herein, for understanding and/or using the products
      and services of GFB or for using this web site as a resource for use of
      GFB’s products or services. Permission is also granted to download,
      reproduce and distribute the images of GFB’s products for the purpose of
      promoting GFB’s products and services. The Permissions granted herein are
      subject to and contingent upon your agreement not to state or imply any
      affiliation, association, sponsorship, license or other relationship
      between yourself and GFB other than what may truthfully exist; and your
      agreement not to libel, disparage, tarnish or otherwise harm the goodwill
      and reputation of GFB or to use GFB’s images for illegal purposes, for
      immoral purposes, to violate any regulation, for any sexually explicit
      purpose or to violate or infringe the rights of any third party.
    </p>
    <p>
      Any other use of materials on this web site – including reproduction for
      purposes other than those noted above, modification, distribution, or
      republication – without the prior written permission of GFB, is strictly
      prohibited.
    </p>
    <p>
      Except as noted in context or herein, all trademarks, service marks, trade
      dress, product names and company names or logos cited herein are the
      property of GFB with ALL RIGHTS RESERVED.
    </p>
    <h4>Third Party Statements</h4>
    <p>
      GFB is not responsible for Third Party Statements. Reviews, critiques,
      editorials, commentary, forum or blog discussions or similar type
      statements posted by third parties are not intended to imply sponsorship,
      adoption, belief or endorsement of them by GFB.
    </p>
    <h4>Links</h4>
    <p>
      As a courtesy, this site may include external links to other sites of
      possible interest. You may access these sites directly or through this
      site. GFB is not responsible for the content in other sites, and links
      from GFB to other sites are not intended to imply endorsement of them by
      GFB.
    </p>
    <p>
      GFB grants permission for third parties to post links to the home page of
      GFB’s website for the purposes of promoting GFB’s goods and services and
      for referring people to GFB’s website. Deep-linking to pages of GFB’s
      website and framing content from GFB’s website is prohibited.
    </p>
    <h4>Third Party Rights Policy</h4>
    <p>
      GFB respects the rights of third parties. In the event that a third party
      believes that content on an GFB site violates that party’s rights, the
      following policy applies:
    </p>
    <p>
      (1) The complaining party should provide a notice to GFB informing GFB of
      the asserted violations. To be effective, a &quot;Notice&quot; must be a
      written communication to GFB’s agent which includes the following:
    </p>
    <p>a) identification of the work or rights alleged to be infringed;</p>
    <p>
      b) identification of the material claimed to be infringing or which is the
      subject of the violation and identifying information for the party
      allegedly committing the violation;
    </p>
    <p>
      c) the complaining party’s contact information, including a complete name,
      address, telephone and e-mail address;
    </p>
    <p>
      d) a statement under penalty of perjury that the complaining party has a
      good faith belief that use of the material is unauthorized, that the
      information in the Notice is accurate and, that the complaining party is
      authorized to act on behalf of the owner; and
    </p>
    <p>
      e) a physical or electronic signature of a person authorized to act on
      behalf of the complaining party.
    </p>
    <p>
      (2) Upon receipt of a sufficient Notice, GFB will review the Notice, will
      notify the party allegedly committing the violation and will temporarily
      remove or disable the allegedly violative material.
    </p>
    <p>
      (3) GFB will provide the allegedly violating party an opportunity for 10
      business days to provide a Counter-Notice defending the allegedly
      violating material and will forward any Counter-Notice to the complaining
      party.
    </p>
    <p>
      (4) Upon receipt of a Counter-Notice, GFB may at its option restore the
      removed or disabled material in no less than 10 business days unless the
      complaining party notifies GFB that it has sought a court order for
      injunctive relief.
    </p>
    <h4>Disclaimer</h4>
    <p>
      GFB makes every effort to provide helpful information and to keep all data
      at this site accurate and up to date, but makes no warranties or
      representations regarding the data. Users agree that all access and use of
      this site and externally linked pages, are at the user&#39;s own risk. GFB
      IS PROVIDING THIS SITE AND ITS CONTENTS ON AN &quot;AS IS&quot; BASIS AND
      MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WITH RESPECT TO THIS
      SITE OR ITS CONTENTS. GFB DISCLAIMS ALL SUCH REPRESENTATIONS AND
      WARRANTIES, INCLUDING FOR EXAMPLE WARRANTIES OF MERCHANTABILITY AND
      FITNESS FOR A PARTICULAR PURPOSE. GFB DOES NOT WARRANT THAT USE OF THE
      WEBSITE OR CONTENT WILL BE UNINTERRUPTED OR ERROR FREE, THAT THE WEBSITE
      OR ANY MATERIALS ARE FREE OF BUGS, VIRUSES OR ERRORS OR THAT ALL DEFECTS
      WILL BE CORRECTED. IN ADDITION, GFB DOES NOT REPRESENT OR WARRANT THAT THE
      INFORMATION ACCESSIBLE VIA THIS SITE IS ACCURATE, COMPLETE OR CURRENT.
      Price and availability information is subject to change without notice.
    </p>
    <p>
      Except as specifically stated on this site, neither GFB nor any of its
      directors, employees or other representatives will be liable for damages
      arising out of or in connection with the use of this site. This is a
      comprehensive limitation of liability that applies to all damages of any
      kind, including (without limitation) compensatory, direct, indirect or
      consequential damages, loss of data, income or profit, loss of or damage
      to property and claims of third parties.
    </p>
    <h4>Cookies</h4>
    <p>
      GFB may use the “cookie” feature of your browser to assist in providing
      you directed products or services. Cookies identify a specific browser and
      certain usage history. GFB may choose to use cookies to identify you and
      to recall previous requests from your browser in order to better serve
      you. You may choose to disable the cookie feature of your browser;
      however, cookies may be required for certain portions of the website to
      function correctly.
    </p>
    <h4>Misuse Of Site</h4>
    <p>
      GFB reserves the right in its sole discretion to refuse service, to edit
      or remove information, to terminate accounts and/or cancel any orders if
      it believes your use of this website violates the policies for use, is a
      misuse of the website, provides inappropriate content, violates any rights
      of others, violates the law or is harmful to the interests of GFB.
    </p>
    <h4>Consent</h4>
    <p>
      In addition to all applicable laws, your use of this website constitutes
      your consent to the above terms and conditions of use. GFB may update this
      policy from time to time. Your continued use of the website constitutes
      your consent to the updated policy.
    </p>
    <h4>Applicable Law</h4>
    <p>
      This site is provided in accordance with the laws of the United States and
      the State of Indiana. By using this site you consent to this exclusive
      choice of law and to exclusive jurisdiction and venue for any disputes
      related to the website in the federal and/or state courts located in or
      whose jurisdiction covers Indianapolis, Indiana.
    </p>
    <h4>Our Address:</h4>
    <span> Go Find Build, LLC </span>
    <span> P.O. Box 503144 </span>
    <span> Indianapolis, Indiana 46250 </span>
    <span> brian@gofindbuild.com </span>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss"></style>
