<template>
  <div class="pl-4 pr-4 pb-4 pt-4">
    <h2>Privacy Policy</h2>

    <span>Effective Date of Last Revision: November 12, 2022</span>
    <h4>Introduction</h4>
    <p>
      Go Find Build, LLC (“GFB”) respects your privacy and the privacy of any
      information you may choose to share with us or which is transmitted to us
      regarding you. GFB’s mission is to connect entities in the commercial and
      residential construction industry to allow them to be more productive and
      successful. Central to this mission is our commitment to be transparent
      about the data we collect about you, how it is used and with whom it is
      shared, as outlined in detail below.
    </p>
    <p>
      The following policy has been implemented by GFB to protect your private
      information. In addition, when you use the GFB website or any other
      products or services provided by GFB (collectively the “Service,” or the
      “System”), you will be subject to the following terms and conditions.
    </p>
    <h4>Information We Collect When You Use GFB</h4>
    <p>
      GFB receives and may store any information you provide. This includes
      information that can identify you (“Personal Identifiable Information” or
      “Private Information”). Personally Identifiable Information is any data
      that could potentially identify you, whether a specific individual or
      entity. This includes, but is not limited to, your name, telephone number,
      postal and e-mail addresses (“Name and Address”), or credit card numbers,
      cardholder names, and expiration dates (“Billing Information”). For job
      candidates, we may also request information such as your educational
      background, driver’s license number, criminal background, occupational
      history, experience, training or the like. For hiring or collaborative
      entities, we may additionally or alternatively request other information
      including corporate identification numbers, liability insurance
      information, certification / bonding information, contractor license
      number, industry identification(s), specialization, entity size, principal
      name and whether any special designations, such as minority, woman or
      veteran owned business designations are appropriate. We may, at our
      discretion, share Name and Address information with affiliated entities,
      business partners, and other independent third-party sources. Billing
      Information will not be shared by GFB. You can choose not to provide some
      or all of this Private Information to us. However, in general, some
      Private Information about you is required in order for you to register as
      a member, participate in the Service, initiate searches, facilitate
      matching, establish connections or otherwise transact and communicate
      using the Service GFB provides.
    </p>
    <p>
      We may also obtain both information and Private Information about you that
      is not private from other users, affiliated entities, business partners
      and other independent third-party sources. We may organize and assemble
      this data in a database (“Aggregate Database”) which may be separate from
      the database we use to store your account information (“Profile
      Database”). Thus some of the information we maintain about you may not be
      visible to you as you use the GFB services.
    </p>
    <p>
      If you access GFB accounts via a social media outlet, or use any of GFB
      social media features, we may access information about you using each
      particular social media provider’s APIs in accordance with each provider’s
      policies. The information we acquire may include your name, e-mail
      address, profile picture, gender, list of friends, and other information
      that you authorize each social media system to provide. This information
      obtained from social media providers may also be included in our Aggregate
      Database along with other information we have obtained. Depending on the
      privacy settings of you and your friends, we may access information that
      you have given to a social media provider regarding your respective
      locations (“Location Data”) to provide you with relevant content.
    </p>
    <p>
      GFB automatically collects some information about your computer that is
      not Private Information when you use the System (“Anonymous Information”).
      This Anonymous Information is available to us as a natural result of your
      use of the technology required to access GFB. For example, we may collect
      your IP address, information about web browser software (such as Firefox,
      Safari, Chrome, or Internet Explorer), information about your mobile
      device that you have allowed the device to share with us, and information
      about referring websites or past browsing history made available by your
      browser. We also may collect information about patterns of online activity
      you may engage in at GFB without specific reference to you personally. We
      use this Anonymous Information to customize your user experience and we
      may, at our discretion, share Anonymous Information we have collected
      about you with affiliated entities, business partners, and other
      independent third-parties.
    </p>
    <h4>How GFB Uses Your Information</h4>
    <p>
      We use sensitive billing information (such as cardholder name, credit card
      number, and expiration date) for the purpose of billing with respect to
      the Service. We use other information about you as indicated above, and
      for the following general purposes:
    </p>
    <p>a. To provide you with the information and services you request;</p>
    <p>b. To present you to other users as a potential match;</p>
    <p>
      c. To provide you with connections to other users where indicated by the
      required parties;
    </p>
    <p>
      d. To manage your account, including processing bills and to communicate
      with you in general;
    </p>
    <p>e. To enable other users to communicate with you when connected;</p>
    <p>
      f. To respond to your questions and comments; to measure interest in and
      improve our Service
    </p>
    <p>
      g. To notify you about special offers, opportunities and products or
      services that may be of interest to you;
    </p>
    <p>
      h. To customize your experience and tailor it specifically to your
      interests;
    </p>
    <p>i. To resolve disputes and/or troubleshoot problems;</p>
    <p>
      j As a resource for affiliated entities, business partners, or other
      independent third- parties to enhance services they provide to you.
    </p>
    <p>
      k. To investigate and prevent prohibited or illegal activities and to
      enforce our terms of use.
    </p>
    <h4>GFB E-mail Communications</h4>
    <p>
      GFB may send you e-mail messages or other electronic messages from time to
      time that contain information about opportunities you have expressed an
      interest in, participated in in the past, or that we think you might be
      interested in for the future. Each e-mail of this type that we send will
      provide you with the opportunity to choose not to receive these e-mail
      messages in the future.
    </p>
    <h4>Sharing Information</h4>
    <p>
      We may share the information we collect about you as described in this
      Privacy Statement or as described at the time of collection or sharing,
      with the following types of entities:
    </p>
    <p>
      a. Other users, such as general contractors, sub-contractors, educators,
      training suppliers, vendors, suppliers or the like.
    </p>
    <p>
      b. Third-party payment transaction vendors who provide services or
      functions on our behalf, including credit card processing, business
      analytics, customer service, marketing, distribution of surveys or
      sweepstakes programs, and fraud prevention. We may also authorize
      third-party vendors to collect information on our behalf, including as
      necessary to operate features of our service or to facilitate the delivery
      of online advertising tailored to your interests. Third-party vendors have
      access to and may collect information only as needed to perform their
      functions and are not permitted to share or use the information for any
      other purpose. They are also required to follow the same data security
      practices that we ourselves adhere to.
    </p>
    <p>
      c. Business partners with whom we may jointly offer products or services,
      or whose products or services may be offered as part of our service. When
      a third party is involved in a product or service you have requested,
      their logo or name will appear, either alone or with ours. If you choose
      to access the services they provide, we may share information about you
      with those partners. This information may include Personal Information
      such as your Name and Address. Although we only collaborate with reputable
      and responsible businesses, Erge does not control, and is not responsible
      for, the privacy practices of these third- party business partners.
    </p>
    <p>
      d. Referring websites or other entities. If you were referred to GFB from
      another app, website, or service, we may share some information about you
      with that referring website. GFB does not control, and is not responsible
      for, the privacy practices of these referring entities.
    </p>
    <p>
      e. Government agencies in response to subpoenas, court orders, or other
      legal process; to establish or exercise our legal rights; to defend
      against legal claims; or as otherwise required by law. In such cases we
      reserve the right to raise or waive any legal objection or right available
      to us.
    </p>
    <p>
      f. Third party investigative services when we believe it is appropriate to
      investigate, prevent, or take action regarding illegal or suspected
      illegal activities; to protect and defend the rights, property, or safety
      of our company or the service, our customers, or others; and in connection
      with our Terms of Service and other agreements.
    </p>
    <p>
      Other than as set out above, you will be notified when personal
      information about you will be shared with third parties, and you will have
      an opportunity to choose not to have us share such information.
    </p>
    <h4>Accessing Your Information</h4>
    <p>
      Your contact information may be updated by accessing your profile using
      the Service. Your account can be closed by contacting a GFB representative
      at brian@gofindbuild.com. After you close an account, you will not be able
      to sign in or access any of your personal information. However, you can
      open a new account at any time. GFB may retain information associated with
      your account for analytical purposes as well as for record keeping
      integrity.
    </p>
    <h4>Choosing How You Want Your Information to be Used</h4>
    <p>
      As discussed above, you can choose not to provide us with any information,
      although it will likely reduce the System’s ability to provide services to
      you. For example, without providing certain personal information, you will
      not be able to interest other users in your profile or identify users who
      might match your need(s). You also can add or update your account
      information, or you can close your account as described above. You will be
      given the opportunity to unsubscribe from commercial e-mails in any such
      message that we send you. If you are a registered member, you can also
      modify your choice at any time. Please note that we reserve the right to
      send you other communications, including service announcements,
      administrative messages, and surveys relating either to your account or to
      transactions you have entered into, without offering you the opportunity
      to opt out of receiving them.
    </p>
    <h4>Cookies and Other Browser Technology</h4>
    <p>
      Cookies are small text files sent from our Site to your browser. They can
      be stored on your computer’s hard drive (if your Web browser permits). The
      GFB service uses cookies for the following general purposes:
    </p>

    <p>
      a. To help us recognize your browser as a previous visitor and save and
      remember any preferences that may have been set while your browser was
      visiting our site. For example, if you register on our site, we may use
      cookies to remember your registration information so that you will not
      need to log into our site each time you visit. We also may record your
      password in a cookie, if you checked the box entitled “Remember Me” or the
      like. Please note that member IDs, passwords, and any other
      account-related data included in such cookies are encrypted for security
      purposes. Unless you register with us, these cookies will not contain any
      personal information.
    </p>
    <p>
      b. To help us customize the content and advertisements provided to you via
      the Service and possibly on other sites across the Internet. For example,
      when you access a page on our website, a cookie is automatically set by
      us, our service providers, or our partners to recognize your browser as
      you navigate on the Internet and to present you with information and
      advertising based on your apparent interests.
    </p>
    <p>
      c. To help measure and research the effectiveness of the Service, its
      features and offerings, advertisements, and e-mail communications (by
      determining which e- mails you open and act upon).
    </p>
    <p>
      d. The Help portion of the toolbar on most browsers will tell you how to
      prevent your browser from accepting new cookies, how to have the browser
      notify you when you receive a new cookie, or how to disable cookies
      altogether. Please note that if you refuse to accept cookies, you may not
      be able to access many of the travel tools offered on the Site.
    </p>
    <p>
      e. In addition to the above cookies, we may use Local Shared Objects, also
      referred to as “flash cookies,” on the Site. These are used to enhance
      your user experience, for example, by storing your user preferences and
      settings, such as your volume/mute settings, and in connection with
      animated content on our website. Local Shared Objects are similar to
      browser cookies, but can store data more complex than simple text. By
      themselves, they cannot do anything to or with the data on your computer.
      Like other cookies, they can only access Personal Information that you
      have provided on this site, and cannot be accessed by other websites.
    </p>
    <p>
      f. This site may also use clear gifs, pixel tags or “Web bugs”, which are
      tiny graphics with a unique identifier, similar in function to cookies,
      that are placed in the code of a page. We use these systems for a variety
      of reasons such as to monitor the traffic patterns of users from one page
      within our sites to another, to deliver or communicate with cookies, to
      understand whether you have come to our site from an online advertisement
      displayed on a third-party website, and to improve site performance. We
      also may allow our service providers to use similar monitoring systems to
      help us understand which e-mails have been opened by recipients and to
      track the visitor traffic and actions on our site. This helps us measure
      the effectiveness of our content and other offerings.
    </p>
    <h4>Advertising</h4>
    <p>
      We may, through cookies and other technologies, collect information about
      your searches or usage of the Service. We may, at our discretion, use this
      information, together with other information we have collected about you,
      to serve you with ads that match your apparent interests. The
      advertisements you may see when using the System are served by us or by
      our service providers. We may allow third parties to collect information
      about your online activities through cookies and other technologies. These
      third parties include (1) business partners, who collect information when
      you view or interact with one of their advertisements on our sites; and
      (2) advertising networks, which collect information about your interests
      when you view or interact with one of the advertisements they place on
      many different websites on the Internet.
    </p>
    <p>
      Please note that we do not have access to or control over cookies or other
      technologies these third parties may use to collect information about your
      interests, and the information practices of these third parties are not
      covered by this Privacy Policy.
    </p>
    <h4>Protecting Your Information</h4>
    <p>
      GFB is committed to maintaining the privacy of your information and will
      treat the privacy of your information with the same or greater security
      protections as GFB provides for its own private information. While no
      website can guarantee security, we have implemented appropriate
      administrative, technical, and physical security procedures to help
      protect the personal information you provide to us. For example, only
      authorized employees are permitted to access Personal Information, and
      they may only do so for permitted business functions. In addition, we use
      encryption when transmitting Personal Information or Billing Information
      between your computer or mobile device and system, and we employ firewalls
      and intrusion detection systems to help prevent unauthorized persons from
      gaining access to your information.
    </p>
    <h4>Children’s privacy</h4>
    <p>
      The System does not offer services directed to children. Children under
      the age of 18 are not allowed to establish accounts on the System, and any
      users we believe to be under 18 can have their accounts terminated at our
      discretion. If a child whom we know to be under 13 sends Personal
      Information to us, we will use that information only to respond directly
      to that child to inform him or her that we must have parental consent
      before receiving his or her Personal Information.
    </p>
    <h4>Territory</h4>
    <p>
      The System does not offer services to anyone located outside of the United
      States. Entities located or residing outside of the United States are not
      allowed to establish accounts on the System, and any users we believe to
      be in violation can have their accounts terminated at our discretion.
    </p>
    <h4>External Links</h4>
    <p>
      Our System may include links to other services or websites. You
      acknowledge, and agree that in the event we link you to other sites, those
      sites do not operate under, and are not bound by, the terms of this
      Privacy Policy. We recommend you examine the privacy statements posted on
      those other websites to understand their procedures for collecting, using,
      and disclosing personal information.
    </p>
    <h4>Updates to this Privacy Policy</h4>
    <p>
      We may update this Privacy Policy in the future at our discretion. We will
      attempt to notify you about material changes to this Privacy Policy by
      sending a notice to the e-mail address you provided to us or by placing a
      prominent notice on the System.
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss"></style>
